export const statuses = {
  OPEN: { code: 'O', name: 'status.open', color: 'primary' },
  RESERVED: { code: 'L', name: 'status.reserved', color: 'secondary' },
  PAUSED: { code: 'P', name: 'status.paused', color: 'error' },
  COMPLETED: { code: 'D', name: 'status.completed', color: 'success' }
}

export const requestTypes = {
  EX: { icon: 'mdi-alert-circle', name: 'Express', color: 'error' },
  SK: { icon: 'mdi-texture-box', name: 'SKRYMMANDE', color: 'secondary' },
  NO: { icon: '', name: 'Normal', color: 'green' },
  PR: { icon: 'mdi-clock-fast', name: 'Priority', color: 'error' }
}

export const statusMap = {
  O: 'OPEN',
  L: 'RESERVED',
  P: 'PAUSED',
  D: 'COMPLETED'
}

export const errors = {}
