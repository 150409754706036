<template>
  <div class="selectQue">
    <v-app-bar color="white" fixed>
      <v-btn icon class="hidden-xs-only">
        <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
      </v-btn>

      <div class="d-flex">
        <img alt="Vattenfall Logo" src="@/assets/logo.svg" width="50%" />
      </div>
      <v-spacer />
      <v-toolbar-title>
        <strong>Nuclear Transportation</strong> App
      </v-toolbar-title>
      <v-spacer />
      <span>
        <v-icon>mdi-account-outline</v-icon>
        {{ isAuthenticated.profile.given_name }} {{ isAuthenticated.profile.family_name }}
      </span>
      <v-btn icon color="gray" @click="handleLogout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-list class="pt-15" two-line subheader>
      <v-subheader>{{ $t('queue.selectQueue') }}</v-subheader>

      <v-list-item
        v-for="item in queues"
        :key="item.name"
        @click="$router.push(`/queue/${item.name}`)"
      >
        <v-list-item-avatar :color="intToHSL(getHashCode(item.name))">
          <v-icon class="white--text" v-text="item.icon" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
          <v-list-item-subtitle v-text="item.subtitle" />
        </v-list-item-content>

        <v-list-item-action>
          <v-chip class="ma-2">{{ item.requests.length }}</v-chip>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset />
    </v-list>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'SelectQueue',
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapGetters(['queues']),
    ...mapState(['requests'])
  },
  methods: {
    handleLogout () {
      this.$adal.logout()
    },
    stringToColour (str) {
      let hash = 0
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    },
    getHashCode (value) {
      let hash = 0
      if (value.length === 0) return hash
      for (let i = 0; i < value.length; i++) {
        hash = value.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
      }
      return hash
    },
    intToHSL (value) {
      const shortened = value % 360
      return 'hsl(' + shortened + ',50%,50%)'
    }
  }
}
</script>
