<template>
  <v-snackbar
    v-if="currentMessage && currentMessage.text"
    v-model="snackbar"
    :bottom="true"
    :color="currentMessage.color || 'error'"
    :timeout="currentMessage.timeout || defaultTimeout"
  >
    {{ currentMessage && currentMessage.text }}
    <v-btn class="float-right" dark @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  props: {},
  data () {
    return {
      snackbar: false,
      defaultTimeout: 5000
    }
  },
  computed: {
    messagesQueue () {
      return this.$store.state.messages
    },
    currentMessage () {
      return this.messagesQueue.find((m) => !m.isRead)
    }
  },
  watch: {
    snackbar () {
      if (!this.snackbar && this.currentMessage) {
        this.currentMessage.isRead = true
        console.log('marking current message as read')
      }
    },
    currentMessage () {
      if (this.currentMessage) {
        console.log('current message changed', this.currentMessage.text)
        setTimeout(() => {
          this.snackbar = true
        }, 100)
      }
    }
  },
  created () {
    this.$eventBus.$on('SHOW_MESSAGE', (message) => {
      this.messagesQueue.push(message)
    })
  }
}
</script>
