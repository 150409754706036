import Vue from 'vue'
import VueRouter from 'vue-router'

import SelectQueue from '../views/SelectQueue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SelectQueue',
    component: SelectQueue
  },
  {
    path: '/queue/:id',
    name: 'DisplayQueue',

    component: () =>
      import(/* webpackChunkName: "DisplayQueue" */ '../views/DisplayQueue.vue')
  },
  {
    path: '/request/:id',
    name: 'RequestDetails',
    component: () =>
      import(
        /* webpackChunkName: "RequestDetails" */ '../views/RequestDetails.vue'
      )
  }
]

const router = new VueRouter({
  routes
})

export default router
