import dayjs from 'dayjs'
import Vue from 'vue'
import Vuex from 'vuex'

import { statuses } from '@/helpers'

import i18n from '../plugins/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fetching: 0,
    requests: [],
    messages: []

  },
  getters: {
    isFetching: state => {
      return !!state.fetching
    },
    currentRequests: state => {
      return state.requests.filter(tr => !dayjs(tr.datetime).isAfter(dayjs(), 'day')
      )
    },
    notCompletedRequests: (state, getters) => {
      return getters.currentRequests.filter(tr => tr.latestStatus && tr.latestStatus.status !== statuses.COMPLETED.code)
    },
    queues: (state, getters) => {
      return getters.notCompletedRequests.reduce((ag, rq) => {
        ag[rq.queue] ? ag[rq.queue].requests.push(rq) : ag[rq.queue] = { name: rq.queue, icon: rq.queueIcon || 'mdi-truck', requests: [rq] }
        return ag
      }, {})
    },
    findRequestByItemCode: state => code => {
      return state.requests.find(tr => tr.items.find(it => it.verificationId === code))
    }
  },

  mutations: {
    /*  commit('MUTATE', {
      property: <propertyNameHere>,
      with: <valueGoesHere>
    }) */
    MUTATE (state, payload) {
      state[payload.property] = payload.with
    },
    IS_FETCHING (state) {
      state.fetching++
    },
    FETCHING_DONE (state) {
      state.fetching--
    },
    UPDATE_REQUEST (state, updatedRequest) {
      state.requests = [
        ...state.requests.filter(tr => tr.id !== updatedRequest.id),
        updatedRequest
      ]
    },
    CREATE_MESSAGE (state, message) {
      state.messages.push({ isRead: false, ...message })
    },
    MARK_ITEM_AS_SCANNED (state, { item, code }) {
      item.verificationId = code
      item.verified = true
      item.verifiedTime = new Date()
    },
    MARK_REQUEST_DEST_AS_SCANNED (state, request) {
      Vue.set(request, 'isDestinationScanned', true)
    }

  },
  actions: {
    async fetchData ({ rootState, commit, dispatch }, payload) {
      commit('IS_FETCHING')
      try {
        const response = await this._vm.$api({
          method: payload.body ? 'post' : 'get',
          url: payload.url,
          data: payload.body ? payload.body : {},
          params: payload.params
        })
        if (payload.stateProperty) {
          commit('MUTATE', {
            property: payload.stateProperty,
            with: response.data
          })
        }
        return response.data
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: response && response.data ? response.data.message : error })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    async postStatus ({ commit, dispatch, state }, { requestId, status }) {
      commit('IS_FETCHING')
      try {
        const response = await this._vm.$api({
          method: 'put',
          url: `/requests/${requestId}/status`,
          data: status
        })
        commit('UPDATE_REQUEST', response.data)
        return response.data
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: response && response.data ? response.data.message : error })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    async reserveRequest ({ commit, dispatch, state }, requestId) {
      const status = {
        comment: '',
        status: statuses.RESERVED.code
        // userName: this._vm.isAuthenticated.userName.split('@')[0]
        // userFriendlyName: this._vm.isAuthenticated.profile
      }
      await dispatch('postStatus', { requestId, status })
      dispatch('createMessage', { color: 'success', text: i18n.t('notification.requestReserved') })
    },
    // /requests/{id}/{recipient}/notify
    async notify ({ commit, dispatch, state }, { requestId, recipient, message }) {
      commit('IS_FETCHING')
      try {
        const response = await this._vm.$api({
          method: 'post',
          url: `/requests/${requestId}/${recipient}/notify`,
          data: { message }
        })
        dispatch('createMessage', { color: 'success', text: i18n.t('notification.messageSent') })
        return response.data
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: response && response.data ? response.data.message : error })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    createMessage ({ commit, state }, message) {
      commit('CREATE_MESSAGE', message)
      return state.messages
    },
    async verifyItem ({ commit, dispatch, state }, { item, code }) {
      try {
        if (item) {
          const response = await this._vm.$api({
            method: 'put',
            url: `/items/${item.id}/verify`,
            data: { verificationId: code }
          })
          console.log('response :', response)
          dispatch('createMessage', { color: 'success', text: i18n.t('notification.itemVerified') })
          commit('MARK_ITEM_AS_SCANNED', { item, code })
        }
        return item
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: response && response.data ? response.data.message : error })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    async verifyDestination ({ commit, dispatch, state }, request) {
      commit('MARK_REQUEST_DEST_AS_SCANNED', request)
    }
  },
  modules: {
  }
})
