import axios from 'axios'
import Vue from 'vue'
import Adal, { AuthenticationContext, AxiosAuthHttp } from 'vue-adal'

import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const resourceId = process.env.VUE_APP_AD_CLIENT_ID
const baseUrl = process.env.VUE_APP_API_URL

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

Vue.use(Adal, {
  config: {
    tenant: process.env.VUE_APP_AD_TENANT,
    clientId: process.env.VUE_APP_AD_CLIENT_ID,
    redirectUri: process.env.VUE_APP_AD_CALLBACK_URL,
    cacheLocation: 'localStorage'
  },
  requireAuthOnInitialize: true,
  router: router
})

Vue.use({
  install (vue, opts = {}) {
    vue.prototype.$api = AxiosAuthHttp.createNewClient({
      axios: axios,
      resourceId,
      router: router,
      baseUrl,
      onTokenSuccess (http, context, token) {
        console.log('got token')
        http.defaults.baseURL = baseUrl
      },
      onTokenFailure (error) {
        console.log(error)
      }
    })
    Vue.axios = vue.prototype.$api
    window.axios = vue.prototype.$api
  }
})

export default Plugin
