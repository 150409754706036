<template>
  <v-app>
    <progress-bar :loading="isFetching" />
    <router-view />
    <snack-bar />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { statuses } from './helpers'
export default {
  name: 'App',
  data: () => ({
    drawer: null
  }),
  computed: {
    ...mapGetters(['isFetching', 'findRequestByItemCode'])
  },
  watch: {

  },
  created () {
    this.fetchState()
    this.$barcodeScanner.init(this.onBarcodeScanned)

    setInterval(() => { this.fetchState() }, 15000)
  },
  destroyed () {
    this.$barcodeScanner.destroy()
  },
  methods: {
    ...mapActions(['fetchData', 'createMessage', 'reserveRequest']),
    // findRequestByItemCode: this.$store.getters.findRequestByItemCode,
    fetchState () {
      this.fetchData({ url: 'requests/all', stateProperty: 'requests' })
    },
    onBarcodeScanned (barcode) {
      console.log('scanned code: ', barcode)
      if (/^\d/.test(barcode)) {
        const request = this.findRequestByItemCode(barcode)
        if (request) {
          const newPath = `/request/${request.id}`
          switch (request.latestStatus.status) {
            case statuses.OPEN.code:
            case statuses.PAUSED.code:
              console.log('reserve')
              this.reserveRequest(request.id)
              break
            case statuses.RESERVED.code:
              console.log('navigate')
              if (this.$router.currentRoute.path !== newPath) {
                this.$router.push(newPath)
              } else { this.$eventBus.$emit('item-scanned', barcode) }
          }
        } else {
          this.createMessage({ color: 'warning', text: this.$t('notification.codeNotFound') })
        }
      } else {
        this.$eventBus.$emit('destination-scanned', barcode)
      }
    },
    resetBarcode () {
      this.$barcodeScanner.getPreviousCode()
    }
  }
}
</script>
