import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import './plugins/axios'
import './registerServiceWorker'

import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import Vue from 'vue'
import VueBarcodeScanner from 'vue-barcode-scanner'

import App from './App.vue'
import filters from './filters'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// setup global eventbus object
Vue.prototype.$eventBus = new Vue()

// automatic global components registration
const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  console.info('registering component:', fileName)
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

const scannerOptions = {
  sensitivity: 50, // default is 100
  requiredAttr: false, // default is false
  controlSequenceKeys: null, // ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}

Vue.use(VueBarcodeScanner, scannerOptions)

// global filters registration
for (const name in filters) {
  Vue.filter(name, filters[name])
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
